<template>
  <div>
    <b-card>
      <b-card-title title="Documentos a Cargar"></b-card-title>
      <b-card-body>
        <b-row v-if="processing">
          <b-spinner label="Cargando..." />
        </b-row>
        <b-row v-else>
          <b-col>
            <b-table
              responsive
              :fields="fields"
              :items="items"
              :tbody-tr-class="rowClass"
            >
              <template #cell(document)="data">
                {{ data.item.document }}
              </template>
              <template #cell(description)="data">
                {{ data.item.description }}
              </template>
              <template #cell(farmName)="data">
                {{ data.item.farmName }}
              </template>
              <template #cell(status)="data">
                {{ data.item.status }}
              </template>
              <template #cell(deadLine)="data"
                >{{ transformDateInTable(data.item.deadLine) }}
              </template>
              <template #cell()="data">
                <b-row>
                  <b-col col sm="10">
                    <b-form-group label-for="basicInput">
                      <b-form-file
                        placeholder="Seleccione..."
                        class="cursor-pointer"
                        size="sm"
                        v-model="documentFile"
                        :disabled="activatedButton(data.item.status)"
                      />
                      <b-card-text
                        @click="openDocument(fileDocumentUrl)"
                        v-if="fileDocumentUrl"
                        class="cursor-pointer sosty-font-smaller"
                        :disabled="activatedButton(data.item.status)"
                      >
                      </b-card-text>
                    </b-form-group>
                  </b-col>
                  <b-col col sm="2">
                    <b-button
                      variant="primary"
                      size="sm"
                      @click="saveDocumentFarmCalendar(data.item)"
                      :disabled="activatedButton(data.item.status)"
                    >
                      <feather-icon icon="SaveIcon" />
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BLink,
  BButton,
  BImg,
  BSpinner,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  BFormGroup,
  BTable,
  BAlert,
  BBadge,
  BFormFile,
  BForm,
  BFormSelect,
  BFormSelectOption,
  BFormCheckbox,
  BCard,
  BCardTitle,
  BCardText,
  BCardBody,
  BCalendar,
  BDropdown,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
import { getUserData } from "@/auth/utils";
import departamentos from "../../assets/departamentos-municipios.json";
import axios from "@axios";
import axiosIns from "@/libs/axios";
import moment from "moment";
import { activated } from "vue-echarts";
import { title } from "echarts/lib/theme/dark";
export default {
  components: {
    BAlert,
    BLink,
    BButton,
    BCardText,
    BImg,
    BSpinner,
    BBadge,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BFormFile,
    BTable,
    BFormSelect,
    BFormSelectOption,
    BFormCheckbox,
    BCardBody,
    BCard,
    BCardTitle,
    BCalendar,
    BDropdown,
    Cleave,
  },
  data() {
    return {
      userID: null,
      carbonFarmID: null,
      statusFarmDocument: false,
      documentFile: null,
      fileDocumentUrl: null,
      processing: false,
      documentsCarbonFarmInformation: [],
      fields: [
        { key: "document", label: "Documento" },
        { key: "farmName", label: "Finca" },
        { key: "description", label: "Descripción" },
        { key: "status", label: "Estado" },
        { key: "deadLine", label: "Vence" },
        "Soporte",
      ],
      items: [],
    };
  },
  created() {
    var userData = getUserData();
    if (userData) {
      this.userID = userData.userID;
    }
    this.getDocumentsCarbonFarmByUserID();
  },
  watch: {
    documentFile: function(val) {
      this.documentFile = val;
      this.uploadFile(val, "documentFile");
    },
  },
  methods: {
    getDocumentsCarbonFarmByUserID() {
      axios
        .get("/CarbonFarm/GetDocumentsByUserID", {
          params: { userID: this.userID },
        })
        .then((response) => {
          this.items = response.data;
        });
    },
    openDocument(urlDoc) {
      window.open(urlDoc, "_blank");
    },
    saveDocumentFarmCalendar(docInformation) {
      this.processing = true;
      var payload = {
        documentsCalendarID: docInformation.documentsCalendarID,
        documentFarmCalendarID: null,
        fileUrl: this.fileDocumentUrl,
        carbonFarmID: docInformation.carbonFarmID,
        status: docInformation.status,
        deadlineMonth: this.getMonthFromDate(docInformation.deadLine),
      };
      axios
        .post("/CarbonFarm/SaveDocumentFarmCalendar", payload)
        .then((response) => {
          this.processing = false;
          this.getDocumentsCarbonFarmByUserID();
          this.$bvToast.toast("Archivo Guardado", {
            title: "Ok",
            variant: "success",
            solid: true,
          });
        });
    },
    uploadFile(fileToUpload, fileType) {
      let formData = new FormData();
      formData.append("file", fileToUpload);
      axios
        .post("/File/UploadFile", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data) {
            this.setFileUrl(fileType, response.data);
            this.$bvToast.toast("Documento subido de forma correcta", {
              title: "Ok",
              variant: "success",
              solid: true,
            });
          } else {
            this.$bvToast.toast("Ocurrio un problema", {
              title: "Email",
              variant: "danger",
              solid: true,
            });
          }
        })
        .catch((error) => {
          this.uploadingDocument = false;
          if (error.response) {
            this.$bvToast.toast(error.response.data, {
              title: "Error",
              variant: "danger",
              solid: true,
            });
          } else {
            this.$bvToast.toast(JSON.stringify(error), {
              title: "Error",
              variant: "danger",
              solid: true,
            });
          }
        });
    },
    setFileUrl(fileType, fileUrl) {
      switch (fileType) {
        case "documentFile":
          this.fileDocumentUrl = fileUrl;
          break;
      }
    },
    getMonthFromDate(transformDate) {
      var transformedDate = parseInt(moment(transformDate).format("M"));
      return transformedDate;
    },
    rowClass(item) {
      if (item.status == "En Revisión" || item.status == "Aceptado") {
        return "table-success";
      }
      if (item.status == "Rechazado") {
        return "table-danger";
      }
      const yearNow = new Date().getFullYear();
      const yearDocCalendar = parseInt(moment(item.deadLine).format("YYYY"));

      if (yearDocCalendar == yearNow) {
        const deadlineDocFarmCalendar = parseInt(
          moment(item.deadLine).format("M")
        );
        const today = new Date().getMonth() + 1;
        if (deadlineDocFarmCalendar <= today) {
          return "table-danger";
        }
        if (deadlineDocFarmCalendar > today) {
          return "table-warning";
        }
      } else {
        if (item.status == null || item.status == "Rechazado") {
          return "table-danger";
        }
      }
    },
    activatedButton(status) {
      if (status == "En Revisión" || status == "Aceptado") {
        return true;
      } else {
        return false;
      }
    },
    transformDateInTable(deadline) {
      return moment(deadline).format("DD/MM/YYYY");
    },
  },
};
</script>
<style></style>
